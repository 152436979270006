body {
    font-family: Arial, sans-serif;
    background-color: #f0f2f5;
    margin: 0;
    padding: 0;
}

header {
    width: 100%;
    background-color: white;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.nav-container .logo img {
    width: 80px;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background-image:url("/public/img/bg.jpg");
    padding: 41px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.names{
    padding-left: 100px;
}

.logo {
    flex: 1 1;
    text-align: center;
    margin-bottom: 0px;
    font-size: 30px;
}

.logo h1 {
    margin: 0;
    color: white;
    margin-bottom: 20px;
}

.logo .o {
    color: red;
}

.logo p {
    margin: 10px 0;
    font-size: 1.2em;
    color: #666;
}

.logo a {
    color: #f00;
    text-decoration: none;
}

.login-form {
    flex: 1;
    background-color: #f8f9fa;
    padding: 40px;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.names{
    display: flex;
    justify-content: space-around;

}

.login-form h2 {
    margin-bottom: 20px;
    font-size: 1.5em;
}

.login-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.login-form .input-box{
    width: 100%;
    padding: 10px 0;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
    background-color: #E8F0FE;
    display: flex;
    justify-content: center;
}

.input-box input{
    border: none;
    outline: none;
    background-color: transparent;
    width: 90%;
}

.login-form a {
    display: block;
    margin-bottom: 20px;
    color: #f00a0a;
    text-decoration: none;
    font-size: 0.9em;
}

.login-form button {
    width: 100%;
    padding: 10px;
    background-color: #f00a0a;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

.login-form button:hover {
    background-color: #121213;
}

.highlight {
    color: #f00; /* Texte en rouge */
    font-weight: bold;
}

.language img {
    width: 20px;
    margin-right: 5px;
    vertical-align: middle;
}

ul {
    margin-top: 10px;
    padding-left: 20px;
    list-style-type: disc;
    color: #000000;
}
ul li{
    color: #000000;
}

footer {
    width: 100%;
    background-color: #f8f9fa;
    padding: 5px 0;
    text-align: center;
    font-size: 0.9em;
    color: #666;
    bottom: 0;
}
@media only screen and (max-width: 800px) {
    .container {
        flex-direction: column;
    }
    .logo {
        flex: 1 1;
        text-align: center;
        font-size: 10px;
    }
    .login-form {
        flex: 1;
        background-color: #f8f9fa;
        padding: 20px 40px;
        max-width: 400px;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    }
}