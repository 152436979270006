.levelcircle{
    padding: 5px;
    border-radius: 50%;
    background-color: rgb(159, 159, 159);
    border: 10px solid #1E1E1E;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.done{
    background-color: red;

}
li{
    color: white;
}
.avataruser{
    padding:0 6px ;
}
.main-progress-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.main-progress-box span{
    text-align: start;
}
.box-circle-skills{
    width: 400px;
}
.head-table-skills{
    display: block;
}
.box-brief{
    display: flex;
    justify-content: space-between;
}
.layout-brief{
    flex-direction: row;
    justify-content: space-between;
}
.brief-description{
    width: 50%;
}
.avataruser{
    padding-left: 16px;
}
.css-1a9xegi-MuiButtonBase-root-MuiListItemButton-root{
    padding: 0;
}
.size-box-brief{
    min-height: 209px;

}
.avatarpadding{
    padding-left: 16px;
}
.card-info-cohorte{
    height: 100%;
}
.size-box-drawer{
    padding-left: 240px;
}
.circleiconinfos{
    display:flex; 
    align-items:center;
    justify-content:center; 
    border-radius:50%; 
    border:1px solid white; 
    width:20px; 
    height:20px; 
    margin-right:5px;
    margin-right:5px;
    background-color: white;
}
.circleiconlink{
    display:flex; 
    align-items:center;
    justify-content:center; 
    border-radius:50%; 
    border:2px solid white; 
    width:30px; 
    height:30px; 
    margin-right:15px;
    cursor: pointer;
}
.circleiconlink:hover{
    border:2px solid red; 

}
.infoscohorte h2, .boldtitle{
    width: 100%;
    color: #ED462F;
    font-size: 20px;
    margin-top: 0;

}
.datebrief{
    display: flex
}
.row1 .marge{
    margin-left: 10px;
}
.specialisation{
    border: none;
    background: transparent;
    color: white;
    outline: none;
}
.container-spe{
    display: flex;
}
#valider{
    display: none;
}
.row1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items:center
}
.box-profil{
    display: flex;
    flex-direction: row;
}
.row1 .marge{
    margin-left: 10px;
}
.row1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items:center
}
.briefsApprenant{
    margin-top: 0;
}

  
@media only screen and (max-width: 600px) {
    .main-progress-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding: 30px 0; */
    }
    .infobriefs {
        flex-direction: column !important; /* Force la disposition en colonne pour les petits écrans */

      }
    .box-circle-skills{
        width: 250px;
    }
    .infoStud{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .levelcircle{
        width: 45px;
        height: 45px;
    }
    .head-table-skills{
        display: none;
    }
    .box-brief{
        display: 'flex'; 
        justify-content: 'space-between';
        flex-direction: column;
    }
    .layout-brief{
        flex-direction: column;
    }
    .brief-description{
        width: 100%
    }
    .briefsApprenant{
        margin-top: 20px;
    }
    .avataruser{
        padding-left: 0;
    }
    .css-1a9xegi-MuiButtonBase-root-MuiListItemButton-root{
        padding: 0;
    }
    .size-box-brief{
        min-height: fit-content;
    
    }
    .main-progress-box span{
        /* text-align: center; */
    }
    .avatarpadding{
        padding-left: 6px;
    }
    .infoscohorte h2{
        font-size: 20px;
    }
    .card-info-cohorte{
        height: fit-content;
    }
    .size-box-drawer{
        padding-left: 50px;
    }
    .infoscohorte h2, .boldtitle{
        width: 90%;
        margin-top:0
    }
    .datebrief{
        display: none;
    }
    .row1{
        display: flex;
        flex-direction: column;
        align-items:start   

    }
    .row1 .marge{
        margin-left: 0;
    }

    .box-profil{
        display: flex;
        flex-direction: column;
    }
    .objectif-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%; /* Permet à l'élément de prendre toute la largeur disponible */
      }
}